// tslint:disable: no-floating-promises
import { EventEmitter, OnDestroy, OnInit, } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { customSelectRequiredValidator } from "pf_styleguide";
import { distinctUntilChanged } from "rxjs/operators";
import { EventTrackingService } from "../../eventTracking.service";
import { AutoSuggestService } from "../auto-suggest/auto-suggest.service";
import { SportSelectService } from "../../sport-select.service";
import { AutoSuggestComponent } from "../auto-suggest/auto-suggest.component";
var dateTimeFormat = "yyyy-MM-dd HH:mm";
var SearchFormComponent = /** @class */ (function () {
    function SearchFormComponent(autoSuggestService, formBuilder, router, sportSelectService, eventTrackingService) {
        this.autoSuggestService = autoSuggestService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.sportSelectService = sportSelectService;
        this.eventTrackingService = eventTrackingService;
        this.layout = "vertical";
        // tslint:disable-next-line:prefer-output-readonly no-output-named-after-standard-event
        this.close = new EventEmitter();
        this.sports = [];
        this.forceReload = new EventEmitter();
        this.buildForm();
    }
    Object.defineProperty(SearchFormComponent.prototype, "sport", {
        get: function () {
            return this.form.get("sport");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchFormComponent.prototype, "location", {
        get: function () {
            return this.form.get("location");
        },
        enumerable: true,
        configurable: true
    });
    SearchFormComponent.prototype.ngOnInit = function () {
        this.subscriptionSports = this.collectSports();
        this.setupSportChanges();
    };
    SearchFormComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptionSports)
            this.subscriptionSports.unsubscribe();
    };
    SearchFormComponent.prototype.submit = function () {
        if (this.location.value === "" || this.sport.invalid) {
            this.autoSuggest.markAsDirty();
        }
        else {
            this.close.emit();
            if (this.pitch) {
                this.trackOpenFromPitch(this.pitch);
                this.router.navigate([this.pitch.links.weblink]);
            }
            else if (this.venue) {
                this.takeUserToResultsPageWithLatAndLong(this.venue.attributes.latitude, this.venue.attributes.longitude);
            }
            else if (this.googleLocation) {
                var queryParams = {
                    // tslint:disable-next-line: no-unbound-method
                    latitude: this.googleLocation.geometry.location.lat,
                    // tslint:disable-next-line: no-unbound-method
                    longitude: this.googleLocation.geometry.location.lng,
                    online_booking: undefined,
                    starts: undefined,
                    ends: undefined,
                };
                if (this.dateTimeSelection && this.dateTimeSelection.date) {
                    var date = this.dateTimeSelection.date;
                    var time = this.dateTimeSelection.time;
                    var momentDateTime = moment({
                        year: date.year,
                        month: date.month - 1,
                        day: date.day,
                        hour: time ? time.hour : 7,
                        minute: 0,
                    });
                    queryParams.online_booking = true;
                    queryParams.starts = momentDateTime.format(dateTimeFormat);
                    // tslint:disable-next-line: prefer-conditional-expression
                    if (time) {
                        queryParams.ends = momentDateTime
                            .add(3, "hours")
                            .format(dateTimeFormat);
                    }
                    else {
                        queryParams.ends = momentDateTime
                            .add(16, "hours")
                            .format(dateTimeFormat);
                    }
                }
                this.router.navigate([
                    "uk",
                    "results",
                    this.getSportFromSelect(this.sport.value).id,
                ], { queryParams: queryParams });
            }
            else {
                this.takeUserToResultsPageSearchTerm(this.location.value);
            }
        }
        this.pitch = undefined;
        this.venue = undefined;
        this.googleLocation = undefined;
    };
    SearchFormComponent.prototype.buildForm = function () {
        this.form = this.formBuilder.group({
            sport: [this.defaultSelectValue(), customSelectRequiredValidator()],
            location: [this.autoSuggestService.location, Validators.required],
        });
    };
    SearchFormComponent.prototype.getSportFromSelect = function (value) {
        return value.data;
    };
    SearchFormComponent.prototype.venueSelected = function (venue) {
        this.venue = venue;
    };
    SearchFormComponent.prototype.pitchSelected = function (pitch) {
        this.pitch = pitch;
    };
    SearchFormComponent.prototype.locationSelected = function (googleLocation) {
        this.googleLocation = googleLocation;
    };
    SearchFormComponent.prototype.dateTimeSelected = function (dateTimeSelection) {
        this.dateTimeSelection = dateTimeSelection;
    };
    SearchFormComponent.prototype.defaultSelectValue = function () {
        return {
            description: "Select a sport",
            icon: "custom-sports",
            // tslint:disable-next-line:no-null-keyword
            data: null,
        };
    };
    SearchFormComponent.prototype.createSportSelection = function (data) {
        var _this = this;
        this.sports = [];
        data.forEach(function (item) {
            _this.sports = _this.sports.concat([
                {
                    description: item.attributes.name,
                    data: item,
                    icon: item.id,
                },
            ]);
        });
    };
    SearchFormComponent.prototype.collectSports = function () {
        var _this = this;
        return this.sportSelectService.getSportsAsync().subscribe(function (data) {
            _this.createSportSelection(data);
            var sportFound = false;
            _this.sports.forEach(function (option) {
                var sport = _this.getSportFromSelect(option);
                if (sport && sport.id === _this.sportSelectService.getSport()) {
                    _this.sport.setValue(option);
                    sportFound = true;
                }
            });
            if (!sportFound) {
                _this.sport.setValue(_this.defaultSelectValue());
                _this.sport.markAsUntouched();
            }
        });
    };
    SearchFormComponent.prototype.takeUserToResultsPageWithLatAndLong = function (latitude, longitude) {
        this.router.navigate(["uk", "results", this.getSportFromSelect(this.sport.value).id], {
            queryParams: { latitude: latitude, longitude: longitude },
        });
    };
    SearchFormComponent.prototype.takeUserToResultsPageSearchTerm = function (searchTerm) {
        this.router.navigate([
            "uk",
            "results",
            this.getSportFromSelect(this.sport.value).id,
            searchTerm,
        ]);
    };
    SearchFormComponent.prototype.setupSportChanges = function () {
        var _this = this;
        this.sport.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (data) {
            var sport = _this.getSportFromSelect(data);
            if (sport) {
                _this.sportSelectService.setSport(sport.id);
            }
            _this.forceReload.emit();
        });
    };
    SearchFormComponent.prototype.trackOpenFromPitch = function (pitch) {
        this.eventTrackingService.trackAutoCompleteVenueSuggestion(pitch.attributes.name + " | " + pitch.attributes.sport + " | " + pitch.attributes.format + " | " + pitch.attributes.surface, this.autoSuggestService.lastInputValue);
    };
    return SearchFormComponent;
}());
export { SearchFormComponent };
