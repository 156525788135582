import {
    AuthServiceConfig,
    FacebookLoginProvider,
    GoogleLoginProvider,
} from "angular-6-social-login";
import { AppleLoginProvider } from "./apple-login/apple.provider";

// tslint:disable-next-line:no-require-imports
const configs = require("./../../../../config.json");

export const getSocialAuthServiceConfigs = () => {
    return new AuthServiceConfig([
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(configs.facebookAppId),
        },
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(configs.googleAuthId),
        },
        {
            id: AppleLoginProvider.PROVIDER_ID,
            provider: new AppleLoginProvider(
                configs.appleAppId,
                { scope: 'email name' },
                configs.canonicalDomain
            ),
        }
    ]);
};
