import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import {
    AuthService as SocialAuthService,
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialUser,
} from "angular-6-social-login";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, from, iif, Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";

import { ConfigureService } from "../../../configure/configure.service";
import { BrowserService } from "../../browser.service";
import { GenericService } from "../../generic.service";
import { User } from "../../model/api/user";
import { CreateUserApi } from "../../model/createUserApi";
import { MlpHelper } from "./../../mlpHelper";
import { AuthResponseSuccess } from "./AuthResponseSuccess";
import { AppleLoginProvider } from "./apple-login/apple.provider";

const LOGINTOKEN = "loginPlatform";
const DEFAULT = "facebook";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    user: User;
    sessionChecked: BehaviorSubject<boolean> = new BehaviorSubject(false);
    rememberMe = false;

    openSignInEvent: EventEmitter<void> = new EventEmitter();
    openSignUpEvent: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly http: HttpClient,
        private readonly config: ConfigureService,
        private readonly genericService: GenericService,
        private readonly cookieService: CookieService,
        private readonly browserService: BrowserService,
        private readonly socialAuthService: SocialAuthService
    ) {
        if (this.browserService.isBrowser) {
            this.validateSession();
        }
    }

    login(username: string, password: string): Observable<User> {
        this.cookieService.remove(LOGINTOKEN);
        const headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        });

        const body = `_username=${encodeURIComponent(
            username
        )}&_password=${encodeURIComponent(password)}`;

        return this.http
            .post(`${this.config.params.apiClientUri}/login_check`, body, {
                headers,
            })
            .pipe(
                // tslint:disable-next-line: no-void-expression
                map((data: AuthResponseSuccess) => this.saveToken(data)),
                switchMap(() => this.userGetMe()),
                catchError(this.genericService.handleError)
            );
    }

    loginWithSocialToken(
        socialPlatform: string,
        token: string
    ): Observable<User> {
        return this.http
            .post(
                `${this.config.params.apiClientUri}/login_${socialPlatform}`,
                { id_token: token }
            )
            .pipe(
                // tslint:disable-next-line: no-void-expression
                map((data: AuthResponseSuccess) => this.saveToken(data)),
                tap((_) => this.cookieService.put(LOGINTOKEN, socialPlatform)),
                switchMap(() => this.userGetMe()),
                catchError(this.genericService.handleError)
            );
    }

    socialVerification(socialPlatform): Observable<SocialUser | any> {
        let socialPlatformProvider = '';

        switch (socialPlatform) {
            case 'google':
                socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
                break;
            case 'apple':
                socialPlatformProvider = AppleLoginProvider.PROVIDER_ID;
                break;
            case 'facebook':
            default:
                socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
                break;
        }

        return from(this.socialAuthService.signIn(socialPlatformProvider)).pipe(
            mergeMap((socialData: SocialUser) =>
                iif(
                    () => !socialData.email && socialData.provider !== AppleLoginProvider.PROVIDER_ID,
                    throwError([
                        {
                            title: "To continue with Facebook, please enable your email address permissions in your Facebook account.",
                        },
                    ]),
                    of(socialData)
                )
            ),
            switchMap((socialData) =>
                this.loginWithSocialToken(
                    socialPlatform,
                    socialPlatform === DEFAULT
                        ? socialData.token
                        : socialData.idToken
                )
            ),
            // tslint:disable-next-line:no-unnecessary-callback-wrapper
            catchError((errors) => throwError(errors))
        );
    }

    signUp(user: CreateUserApi): Observable<User> {
        return this.http
            .post(`${this.config.params.apiClientUri}/users`, user, {
                observe: "response",
            })
            .pipe(
                switchMap(() =>
                    this.login(
                        user.data.attributes.email,
                        user.data.attributes.password
                    )
                ),
                catchError(this.genericService.handleError)
            );
    }

    userGetMe(): Observable<User> {
        return this.getMe().pipe(
            tap((data: User) => (this.user = data)),
            tap(() => {
                this.sessionChecked.next(true);
            })
        );
    }

    validateSession(): void {
        if (this.getToken()) {
            // tslint:disable-next-line: no-void-expression no-empty
            this.userGetMe().subscribe(
                () => {},
                () => this.logout()
            );
        } else {
            this.logout();
        }
    }

    logout(): void {
        this.cookieService.remove("token");
        this.cookieService.remove(LOGINTOKEN);
        this.user = undefined;
        this.sessionChecked.next(true);
    }

    getMe(): Observable<any> {
        return this.http.get(
            `${this.config.params.apiClientUri}/users/me`,
            this.addAuthHeaders(this.getToken())
        );
    }

    getLoginPlatform(): string {
        return this.cookieService.get(LOGINTOKEN);
    }

    private saveToken(data: AuthResponseSuccess): void {
        this.cookieService.put(
            "token",
            data.token,
            this.rememberMe ? MlpHelper.oneWeekFromNow() : null
        );
    }

    private getToken(): string {
        return this.cookieService.get("token");
    }

    // tslint:disable-next-line:prefer-function-over-method
    private addAuthHeaders(apiTokenHeader: string): { headers: HttpHeaders } {
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${apiTokenHeader}`,
            }),
        };
    }
}
