// tslint:disable: no-floating-promises
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import * as moment from "moment";

import { customSelectRequiredValidator, ISelectMolecule } from "pf_styleguide";
import { Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { EventTrackingService } from "../../eventTracking.service";

import { DateTimeSelection } from "../auto-suggest/auto-suggest.interface";
import { AutoSuggestService } from "../auto-suggest/auto-suggest.service";

import { CitySelectService } from "../../city-select.service";
import { SportSelectService } from "../../sport-select.service";

import { AutoSuggestComponent } from "../auto-suggest/auto-suggest.component";

import { Pitch } from "../../model/pitch";
import { Sport } from "../../model/sport";
import { Venue } from "../../model/venue";

const dateTimeFormat = "yyyy-MM-dd HH:mm";

@Component({
    selector: "pf-search-form",
    templateUrl: "./search-form.component.html",
    styleUrls: ["./search-form.component.scss"],
})
export class SearchFormComponent implements OnInit, OnDestroy {
    @ViewChild(AutoSuggestComponent) autoSuggest: AutoSuggestComponent;

    @Input() layout = "vertical";
    @Input() uniqueInputId: string;
    // tslint:disable-next-line:prefer-output-readonly no-output-named-after-standard-event
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    sports: Array<ISelectMolecule> = [];
    forceReload = new EventEmitter();
    form: FormGroup;

    venue: Venue;
    pitch: Pitch;
    googleLocation: google.maps.places.PlaceResult;
    dateTimeSelection: DateTimeSelection;

    get sport(): AbstractControl {
        return this.form.get("sport");
    }
    get location(): AbstractControl {
        return this.form.get("location");
    }

    subscriptionSports: Subscription;

    constructor(
        private readonly autoSuggestService: AutoSuggestService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly sportSelectService: SportSelectService,
        private readonly eventTrackingService: EventTrackingService
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.subscriptionSports = this.collectSports();
        this.setupSportChanges();
    }

    ngOnDestroy(): void {
        if (this.subscriptionSports) this.subscriptionSports.unsubscribe();
    }

    submit(): void {
        if (this.location.value === "" || this.sport.invalid) {
            this.autoSuggest.markAsDirty();
        } else {
            this.close.emit();

            if (this.pitch) {
                this.trackOpenFromPitch(this.pitch);
                this.router.navigate([this.pitch.links.weblink]);
            } else if (this.venue) {
                this.takeUserToResultsPageWithLatAndLong(
                    this.venue.attributes.latitude,
                    this.venue.attributes.longitude
                );
            } else if (this.googleLocation) {
                const queryParams = {
                    // tslint:disable-next-line: no-unbound-method
                    latitude: this.googleLocation.geometry.location.lat,
                    // tslint:disable-next-line: no-unbound-method
                    longitude: this.googleLocation.geometry.location.lng,
                    online_booking: undefined,
                    starts: undefined,
                    ends: undefined,
                };

                if (this.dateTimeSelection && this.dateTimeSelection.date) {
                    const date = this.dateTimeSelection.date;
                    const time = this.dateTimeSelection.time;

                    const momentDateTime = moment({
                        year: date.year,
                        month: date.month - 1,
                        day: date.day,
                        hour: time ? time.hour : 7,
                        minute: 0,
                    });

                    queryParams.online_booking = true;
                    queryParams.starts = momentDateTime.format(dateTimeFormat);

                    // tslint:disable-next-line: prefer-conditional-expression
                    if (time) {
                        queryParams.ends = momentDateTime
                            .add(3, "hours")
                            .format(dateTimeFormat);
                    } else {
                        queryParams.ends = momentDateTime
                            .add(16, "hours")
                            .format(dateTimeFormat);
                    }
                }
                this.router.navigate(
                    [
                        "uk",
                        "results",
                        this.getSportFromSelect(this.sport.value).id,
                    ],
                    { queryParams }
                );
            } else {
                this.takeUserToResultsPageSearchTerm(this.location.value);
            }
        }

        this.pitch = undefined;
        this.venue = undefined;
        this.googleLocation = undefined;
    }

    buildForm(): void {
        this.form = this.formBuilder.group({
            sport: [this.defaultSelectValue(), customSelectRequiredValidator()],
            location: [this.autoSuggestService.location, Validators.required],
        });
    }

    getSportFromSelect(value: ISelectMolecule): Sport {
        return value.data;
    }

    venueSelected(venue: Venue): void {
        this.venue = venue;
    }

    pitchSelected(pitch: Pitch): void {
        this.pitch = pitch;
    }

    locationSelected(googleLocation: google.maps.places.PlaceResult): void {
        this.googleLocation = googleLocation;
    }

    dateTimeSelected(dateTimeSelection: DateTimeSelection): void {
        this.dateTimeSelection = dateTimeSelection;
    }

    protected defaultSelectValue(): ISelectMolecule {
        return {
            description: "Select a sport",
            icon: "custom-sports",
            // tslint:disable-next-line:no-null-keyword
            data: null,
        };
    }

    private createSportSelection(data: Array<Sport>): void {
        this.sports = [];
        data.forEach((item) => {
            this.sports = [
                ...this.sports,
                {
                    description: item.attributes.name,
                    data: item,
                    icon: item.id,
                },
            ];
        });
    }

    private collectSports(): Subscription {
        return this.sportSelectService.getSportsAsync().subscribe((data) => {
            this.createSportSelection(data);

            let sportFound = false;

            this.sports.forEach((option) => {
                const sport = this.getSportFromSelect(option);
                if (sport && sport.id === this.sportSelectService.getSport()) {
                    this.sport.setValue(option);
                    sportFound = true;
                }
            });

            if (!sportFound) {
                this.sport.setValue(this.defaultSelectValue());
                this.sport.markAsUntouched();
            }
        });
    }

    private takeUserToResultsPageWithLatAndLong(
        latitude: string,
        longitude: string
    ): void {
        this.router.navigate(
            ["uk", "results", this.getSportFromSelect(this.sport.value).id],
            {
                queryParams: { latitude, longitude },
            }
        );
    }

    private takeUserToResultsPageSearchTerm(searchTerm: string): void {
        this.router.navigate([
            "uk",
            "results",
            this.getSportFromSelect(this.sport.value).id,
            searchTerm,
        ]);
    }

    private setupSportChanges(): void {
        this.sport.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((data) => {
                const sport = this.getSportFromSelect(data);
                if (sport) {
                    this.sportSelectService.setSport(sport.id);
                }

                this.forceReload.emit();
            });
    }

    private trackOpenFromPitch(pitch: Pitch): void {
        this.eventTrackingService.trackAutoCompleteVenueSuggestion(
            `${pitch.attributes.name} | ${pitch.attributes.sport} | ${pitch.attributes.format} | ${pitch.attributes.surface}`,
            this.autoSuggestService.lastInputValue
        );
    }
}
