import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { AuthService as SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, } from "angular-6-social-login";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, from, iif, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ConfigureService } from "../../../configure/configure.service";
import { BrowserService } from "../../browser.service";
import { GenericService } from "../../generic.service";
import { MlpHelper } from "./../../mlpHelper";
import { AppleLoginProvider } from "./apple-login/apple.provider";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../configure/configure.service";
import * as i3 from "../../generic.service";
import * as i4 from "ngx-cookie";
import * as i5 from "../../browser.service";
import * as i6 from "angular-6-social-login";
var LOGINTOKEN = "loginPlatform";
var DEFAULT = "facebook";
var AuthService = /** @class */ (function () {
    function AuthService(http, config, genericService, cookieService, browserService, socialAuthService) {
        this.http = http;
        this.config = config;
        this.genericService = genericService;
        this.cookieService = cookieService;
        this.browserService = browserService;
        this.socialAuthService = socialAuthService;
        this.sessionChecked = new BehaviorSubject(false);
        this.rememberMe = false;
        this.openSignInEvent = new EventEmitter();
        this.openSignUpEvent = new EventEmitter();
        if (this.browserService.isBrowser) {
            this.validateSession();
        }
    }
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        this.cookieService.remove(LOGINTOKEN);
        var headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        });
        var body = "_username=" + encodeURIComponent(username) + "&_password=" + encodeURIComponent(password);
        return this.http
            .post(this.config.params.apiClientUri + "/login_check", body, {
            headers: headers,
        })
            .pipe(
        // tslint:disable-next-line: no-void-expression
        map(function (data) { return _this.saveToken(data); }), switchMap(function () { return _this.userGetMe(); }), catchError(this.genericService.handleError));
    };
    AuthService.prototype.loginWithSocialToken = function (socialPlatform, token) {
        var _this = this;
        return this.http
            .post(this.config.params.apiClientUri + "/login_" + socialPlatform, { id_token: token })
            .pipe(
        // tslint:disable-next-line: no-void-expression
        map(function (data) { return _this.saveToken(data); }), tap(function (_) { return _this.cookieService.put(LOGINTOKEN, socialPlatform); }), switchMap(function () { return _this.userGetMe(); }), catchError(this.genericService.handleError));
    };
    AuthService.prototype.socialVerification = function (socialPlatform) {
        var _this = this;
        var socialPlatformProvider = '';
        switch (socialPlatform) {
            case 'google':
                socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
                break;
            case 'apple':
                socialPlatformProvider = AppleLoginProvider.PROVIDER_ID;
                break;
            case 'facebook':
            default:
                socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
                break;
        }
        return from(this.socialAuthService.signIn(socialPlatformProvider)).pipe(mergeMap(function (socialData) {
            return iif(function () { return !socialData.email && socialData.provider !== AppleLoginProvider.PROVIDER_ID; }, throwError([
                {
                    title: "To continue with Facebook, please enable your email address permissions in your Facebook account.",
                },
            ]), of(socialData));
        }), switchMap(function (socialData) {
            return _this.loginWithSocialToken(socialPlatform, socialPlatform === DEFAULT
                ? socialData.token
                : socialData.idToken);
        }), 
        // tslint:disable-next-line:no-unnecessary-callback-wrapper
        catchError(function (errors) { return throwError(errors); }));
    };
    AuthService.prototype.signUp = function (user) {
        var _this = this;
        return this.http
            .post(this.config.params.apiClientUri + "/users", user, {
            observe: "response",
        })
            .pipe(switchMap(function () {
            return _this.login(user.data.attributes.email, user.data.attributes.password);
        }), catchError(this.genericService.handleError));
    };
    AuthService.prototype.userGetMe = function () {
        var _this = this;
        return this.getMe().pipe(tap(function (data) { return (_this.user = data); }), tap(function () {
            _this.sessionChecked.next(true);
        }));
    };
    AuthService.prototype.validateSession = function () {
        var _this = this;
        if (this.getToken()) {
            // tslint:disable-next-line: no-void-expression no-empty
            this.userGetMe().subscribe(function () { }, function () { return _this.logout(); });
        }
        else {
            this.logout();
        }
    };
    AuthService.prototype.logout = function () {
        this.cookieService.remove("token");
        this.cookieService.remove(LOGINTOKEN);
        this.user = undefined;
        this.sessionChecked.next(true);
    };
    AuthService.prototype.getMe = function () {
        return this.http.get(this.config.params.apiClientUri + "/users/me", this.addAuthHeaders(this.getToken()));
    };
    AuthService.prototype.getLoginPlatform = function () {
        return this.cookieService.get(LOGINTOKEN);
    };
    AuthService.prototype.saveToken = function (data) {
        this.cookieService.put("token", data.token, this.rememberMe ? MlpHelper.oneWeekFromNow() : null);
    };
    AuthService.prototype.getToken = function () {
        return this.cookieService.get("token");
    };
    // tslint:disable-next-line:prefer-function-over-method
    AuthService.prototype.addAuthHeaders = function (apiTokenHeader) {
        return {
            headers: new HttpHeaders({
                Authorization: "Bearer " + apiTokenHeader,
            }),
        };
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigureService), i0.inject(i3.GenericService), i0.inject(i4.CookieService), i0.inject(i5.BrowserService), i0.inject(i6.AuthService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
